<template>
  <v-container>
    <v-card height="auto" width="100%" class="justify-center" center outlined mx-auto>
      <v-form id=company >
        <v-layout class="flex_mobile justify-center" >  
          <v-card outlined color="transparent" max-width="500" class="pa-3">
            <div>{{company_code}}</div>
            
            <v-layout wrap>
              <v-flex xs12 lg7>
                <v-text-field dense outlined style="height: 50px;"
                  :loading = "loading"
                  id="company_name" 
                  ref="company_name" 
                  label="경영체명✽" 
                  v-model="company_name" 
                  />
              </v-flex>

              <v-flex xs12 lg5>
                <v-select dense outlined class="ml-1" style="height: 50px;"
                  id="company_type" 
                  ref="company_type" 
                  label="경영체유형✽" 
                  v-model="company_type"
                  :items="companyTypeItems"
                  :menu-props="{ top: false, offsetY: true }"
                  item-text="name"
                  auto
                  return-object
                  />
              </v-flex>
            </v-layout>

            <v-flex lg7>
              <v-text-field dense outlined style="height: 50px;"
                id="post_no" 
                ref="post_no" 
                label="우편번호✽" 
                v-model="post_no" 
                onKeyup="this.value=this.value.replace(/[^0-9]/g,'');"
                maxlength="5"
              />
            </v-flex>
            
            <v-text-field dense outlined style="height: 50px;"
              id="address_1st" 
              ref="address_1st" 
              label="기본주소✽" 
              v-model="address_1st" 
            />

            <v-text-field dense outlined style="height: 50px;"
              id="address_last" 
              ref="address_last" 
              label="상세주소(빌딩명,동호수,상호)" 
              v-model="address_last" 
            />

            <v-layout wrap>
              <v-flex xs6 lg6>
                <v-text-field dense outlined style="height: 50px;"
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
                />
              </v-flex>

              <v-flex xs1 lg1>
                <div style="line-height: 35px; text-align: center;">
                  @
                </div>
              </v-flex>

              <v-flex xs5 lg5>
                <v-combobox dense outlined style="height: 50px;"
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                :menu-props="{ top: false, offsetY: true }"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
              </v-flex>
            </v-layout>
            
            <v-text-field dense outlined style="height: 50px;"
              id="phone" 
              ref="phone" 
              label="전화번호✽" 
              placeholder="숫자만 입력"
              v-model="phone"
              @keyup="getPhoneMask(phone)"
            />
            <!-- <v-text-field dense outlined style="height: 45px; margin-top: 5px" 
              id="business_date" 
              ref="business_date" 
              label="설립일" 
              v-model="business_date" 
            /> -->

            <v-layout wrap>
              <v-flex xs12 lg6>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense outlined class="mr-1" style="height: 50px;"
                        id="business_date" 
                        ref="business_date" 
                        v-model="business_date"
                        label="설립일"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title locale="ko-KR"
                      v-model="business_date"
                      @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          
              
            <v-flex xs12 lg6>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined clearable style="height: 50px;"
                    id="deldate" 
                    ref="deldate" 
                    v-model="deldate"
                    label="삭제일"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title locale="ko-KR"
                  v-model="deldate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
         </v-layout>

      <!-- <v-select align="center" dense outlined style="height: 45px; width: 200px; margin-top: 5px" 
              id="service_grade" 
              ref="service_grade" 
              label="서비스 등급✽" 
              v-model="service_grade" 
              :items="service_gradeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
            />  -->
      
          <v-textarea outlined dense rows="2" name="input-7-4"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
          
          <v-card-actions class="d-block">
            <v-row justify= "center">
              <v-btn class="rounded-pill normal-btn mr-2 mb-5" 
                :loading= "loadingSave"
                id="save" 
                ref="save" 
                type="submit" 
                @click.prevent="save()"
                v-show= "company_code !== '' || $getters.userInfo.grade == 'UT01'"
                >
                저장
              </v-btn>
              <v-btn class="rounded-pill new-btn mb-5 mr-2" 
                id="newRegister" 
                ref="newRegister"
                @click="newRegister()">
                신규
              </v-btn>
            </v-row>
        </v-card-actions>
        </v-card>

        <v-card height="auto" class="dataTable ml-2" outlined color="transparent">
          <v-data-table dense height="600"
          fixed-header
          hide-default-footer
          :items-per-page= "500"
          :loading="loadingTable" 
          :headers="headers"
          :items="companies"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          class="elevation-2"
          id="companies" 
          ref="companies" 
          item-key="company_cd"
          single-select
          sort-by="company_name"
          @click:row="infoDetail"
          >

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color=#3a4f3f
                medium
                @click="childFarms(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-share-variant
              </v-icon>
            </template>
            <span class="tooltip">하위농장/사업장</span>
          </v-tooltip>
        </template>
        </v-data-table>
        <v-card-actions class="justify-center">
          <v-btn rounded-pill class="elevation-3 ma-3 rounded-pill normal-btn" style="width: 135px;"
            id="listCompanies" 
            ref="listCompanies" 
            @click="listCompanies()">
            <img src="@/assets/icon_refresh.svg" alt="새로고침" />
            <span class="ml-1"> 목록고침 </span>
          </v-btn>
        </v-card-actions>

        </v-card>

      </v-layout>

      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";

export default {
  name: 'Company',

  data: () => ({
    headers: [
        { text: '',align: 'start', sortable: false, value: 'actions', width: "50px", groupable: false, class:''},
        { text: '경영체명', align: 'start', sortable: true, value: 'company_name', width: "110px", },
        { text: '코드', align: 'start', sortable: true, value: 'company_cd', width: "25px", },
        { text: '전화번호', value: 'phone1', width: "110px", class: 'green darken-4 white--text' },
        { text: '주소', value: 'address', sortable: false, width: "300px",  },
        { text: '이메일', value: 'email', sortable: false, width: "80x", class: 'green darken-4 white--text' },
        { text: '유형', value: 'company_type_name', sortable: false, width: "120px", },
        { text: '등록일', value: 'regdate', sortable: false, width: "130px",},
        { text: '삭제일', value: 'deldate', sortable: false, width: "130px",},
        { text: '서비스등급', value: 'service', align: 'center', sortable: false, width: "75px", class: 'green darken-4 white--text' },
        { text: '비고', value: 'history', sortable: false, width: "300px",},
        { text: '등록번호', value: 'business_no', sortable: false, width: "110px", class: 'green darken-4 white--text' },
        { text: '설립일', value: 'business_date', sortable: false, width: "110px", class: 'green darken-4 white--text' },
        ],
    companies : [],
    loadingTable:false, 

    company_code : "",
    company_name : "",
    company_type : "",
    companyTypeItems : [],

    // business_no : "",
    post_no : "",
    address_1st : "",
    address_last : "",
    email_id : "",
    email_host : "",
    phone : "",
    // service_grade : "",
    business_date : "",
    deldate : "",
    history : "",
    hint : "",
    
    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,

    loading: false,
    loadingSave: false,
    // phone:null,

  }),

  created() {
    this.listCompanies();

    this.listCompanyType();
    this.listService_grade();

    // console.log("params",this.$route.params,this.isNull(this.$route.params));

    if (!this.isNull(this.$route.params)) {
      this.infoDetail(this.$route.params.company_cd);
    }
    
  },

  methods: {
    // ...mapMutations(['doLogin']),

    listCompanies() {
      this.$store.commit("resMessage","");
      this.loadingTable = true;
      Apis.listCompanies({} ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.business_no = Common.getRegMask(element.business_no);
          element.phone1 = Common.getMask(element.phone1);
          element.service = element.service == "5" ? "프리미엄" : "일반" ;
        });
        this.companies = res.data;
        this.$store.commit("resMessage",res.message);
        this.loadingTable = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listCompanies 호출 오류",err);
        this.$router.push("/");
        this.loadingTable = false;
      }) 
    }, // -----------listCompanies

    childFarms(value) {
      // alert("경영체에 속한 농장/사업장목록으로")
      this.loadingTable = true;
      // console.log(value);
      
      this.$router.push({
                      name:'farm',
                      params: { code: value.company_cd, name: value.company_name}
                      });
      this.loadingTable = false;
    },

    clearData() {
      this.company_code = "";
      this.company_name = "";
      this.company_type = {};
      // this.business_no = "";
      this.post_no = "";
      this.address_1st = "";
      this.address_last = "";
      this.email_id = "";
      this.email_host = "";
      this.phone = "";
      // this.service_grade = {};
      this.business_date = "";
      this.deldate = "";
      this.history = "";
      this.hint = "";
    },

    checkData() {
      if (!this.company_name.trim()) {
        this.$refs.company_name.focus();
        return "경영체명을 입력하세요";
      }
      if (!this.company_type.code) {
        this.$refs.company_type.focus();
        return "경영체 유형을 선택입력하세요";
      }
      if (!this.post_no) {
        this.$refs.post_no.focus();
        return "우편번호를 입력하세요";
      }
      if (!this.address_1st.trim()) {
        this.$refs.address_1st.focus();
        return "기본주소를 입력하세요";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "전화번호를 입력하세요";
      }
      // if (!this.service_grade.code) {
      //   this.$refs.service_grade.focus();
      //   return "서비스등급을 선택입력하세요";
      // }
      return "";
    },
    save() {
      // console.log("checkdata", this.checkData());
      this.$store.commit("resMessage","");
      if (this.company_code == "" && this.$getters.userInfo.grade !== "UT01") {
          alert("경영체를 등록할 권한이 없습니다");
          return;
      }
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.company_code) {
        // console.log("업데이트");
        Apis.updateCompany({
          company_cd: this.company_code,
          company_name: this.company_name.trim(),
          company_type: this.company_type.code,
          // business_no: this.business_no.replace(/[^0-9]/g, ''),
          business_date: this.business_date,
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          deldate: this.deldate,
          // service_grade: this.service_grade.code,
          history: this.history.trim(),
          etc1: "",
          etc2: "",
          etc3: "", 
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.listCompanies();
              // this.$router.push({
              //           name: "ListCompanies",
              //           params: { company_cd: this.company_code}
              // });
              this.clearData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        )
      } else {
        // console.log("인서트",this.email_host.trim());
        Apis.insertCompany({
          company_name: this.company_name.trim(),
          company_type: this.company_type.code,
          // business_no: this.business_no.replace(/[^0-9]/g, ''),
          business_date: this.business_date,
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          deldate: this.deldate,
          // service_grade: this.service_grade.code,
          history: this.history.trim(),
          etc1: "",
          etc2: "",
          etc3: "", 
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.clearData();
              this.listCompanies();
              // 입력자 접근권한 부여 API 에서
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        )
      }
    },

    infoDetail(value, row) {
      row.select(true);

      this.$store.commit("resMessage","");
      this.loading = true;
      this.clearData();

      Apis.infoCompany({
        // session_id: this.$getters.sessionId,
        company_cd: value.company_cd, 
        },(res) => {  
          if (res.result) {
            // console.log(res.data);
            this.company_code = res.data.company_cd;
            this.company_name = res.data.company_name;
            this. company_type = {code: res.data.company_type.substring(0,4), name: res.data.company_type.substring(4)};
            // this.business_no = Common.getRegMask(res.data.business_no);
            this.post_no = res.data.post_no;
            this.address_1st = res.data.address_1st;
            this.address_last = res.data.address_last;
            let email = res.data.email1.split("@",2);
            this.email_id = email[0];
            this.email_host = email[1];
            this.phone = Common.getMask(res.data.phone1);
            // this.service_grade = res.data.service_grade == "5" ? {code: "5" , name: "프리미엄" } : {code: "1" , name: "일반"};
            this.business_date = res.data.business_date;
            this.deldate = res.data.deldate;
            this.history = res.data.history;
            this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loading = false;
            
            this.$store.commit("resMessage",res.message);
          } else {
            this.loading = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류")
            this.loading = false;
            alert(err);
            // console.log(err)
        }
      ) 
    },
    newRegister() {
      this.clearData();
      this.$refs.company_name.focus();
      this.$store.commit("resMessage","새로 등록 준비가 되었습니다.");

    },
    listCompanyType() {
      // BH 모델
      Apis.baseCodeList({
        // session_id: this.$getters.sessionId,
        division: "CM", 
        },(res) => {  
          if (res.result) {
            this.companyTypeItems = [];
            for (let i in res.data) {
            this.companyTypeItems.push({
              code: res.data[i].basecode,
              name: res.data[i].description,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류")
            alert(err);
            // console.log(err)
        }
      ) 
    },
    listService_grade() {
      this.service_gradeItems = [{ code: "1", name: "일반"}, {code: "5", name: "프리미엄" }];
    },
    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },
    getRegMask(val) {
      // let res = Common.getRegMask(val)
      this.business_no = Common.getRegMask(val)
    },
    // searchAddress() { TODO 주소검색 API

    // },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    max-width:100% !important;
  }
}
.dataTable{
  max-width:60%;
}
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}

.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
